<template>
    <v-row class="my-n1">
      <v-col>
          <v-tabs v-model="tab" grow>
            <v-tabs-slider color="orange"></v-tabs-slider>
            <v-tab v-for="item in items" :key="item.id">{{ item.name }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in items" :key="item.id">
              <v-card class="mt-7">
                <UsersList v-if="item.id === ADMIN_USER_MANAGEMENT_USER_TAB"
                :isNewRoleAddedEvent="isNewRoleAddedEvent" />
                <RolesList v-if="item.id === ADMIN_USER_MANAGEMENT_ROLE_TAB"
                @FetchUpdatedRoleListEvent="invokeFetchUpdatedRoleListEvent" />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
      </v-col>
    </v-row>
</template>
<script>
import RolesList from '@/components/admin/RolesList.vue';
import UsersList from '@/components/admin/UsersList.vue';
import Constants from '@/constants';

export default {
  components: {
    RolesList,
    UsersList,
  },

  data() {
    return {
      tab: null,
      isNewRoleAddedEvent: '',
      ADMIN_USER_MANAGEMENT_USER_TAB: Constants.ADMIN_USER_MANAGEMENT_USER_TAB,
      ADMIN_USER_MANAGEMENT_ROLE_TAB: Constants.ADMIN_USER_MANAGEMENT_ROLE_TAB,
      items: [{ id: Constants.ADMIN_USER_MANAGEMENT_USER_TAB, name: this.$t('admin.userManagement.userTab') },
        { id: Constants.ADMIN_USER_MANAGEMENT_ROLE_TAB, name: this.$t('admin.userManagement.roleTab') }],
    };
  },

  methods: {

    /**
     * This is event trigger when new role is added by admin to refresh
     * the role list on new user creation.
     */
    invokeFetchUpdatedRoleListEvent(event) {
      console.log('-----New Role Added ---- by admin......', event);
      this.isNewRoleAddedEvent = event;
    },
  },

};
</script>
<style scoped lang="scss">
</style>
