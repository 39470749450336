<template>
 <v-row justify="center" class="max-width-for-elements">
    <v-dialog
      v-model="addNewUserDialogId"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          color="primary accent-2"
          top
          right
          absolute
          @click.stop="openDialog()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
       <v-card height="100%" class="max-width-for-elements">
      <v-form v-model="isValid" style="height: 100%">
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ isUpdateDialog ?
              $t('admin.userManagement.updateUser') :
              $t('admin.userManagement.createUser')}}</v-toolbar-title>
            <v-spacer></v-spacer>
             <v-toolbar-title>
                <v-btn class="ma-2" outlined v-if="isUpdateDialog" :disabled="!isValid"
               color="white" @click="onClickOfUpdateUser()">{{ $t('app.button.update') }}</v-btn>
                 <v-btn class="ma-2" outlined v-else :disabled="!isValid"
               color="white" @click="onClickOfNewUser()">{{ $t('app.button.add') }}</v-btn>
             </v-toolbar-title>
          </v-toolbar>
           <v-row>
               <v-col cols="12" md="8">
                 <v-alert
                 outlined v-if="isValidationError"
                  type="error">
                  {{ $t('userValidations.ADMIN_ITEM_ALREADY_EXIST', {item: "User"}) }}</v-alert>
              </v-col>
            </v-row>
           <v-card fill-height>
              <v-list>
                <v-subheader>User Details</v-subheader>
                <v-list-item class="max-width-for-elements">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-text-field
                        prepend-icon="person"
                        :rules="userNameRules"
                        v-model="user.userName"
                        :label="$t('loginUserName')"
                        required
                      ></v-text-field>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item  class="max-width-for-elements">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-text-field
                        prepend-icon="email"
                        :rules="emailRules"
                        v-model="user.email"
                        :label="$t('datatable.tableHeaders.email')"
                        required
                        :disabled="isUpdateDialog ? true : false"
                      ></v-text-field>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="max-width-for-elements">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-combobox prepend-icon="mdi-account-multiple"
                        v-model="user.userType"
                        :items="ghmUserTypesList"
                        required
                        :rules="userTypeRules"
                        :label="$t('admin.userManagement.userType')"
                        chips
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)"
                          >
                            <v-avatar
                              class="accent white--text"
                              left
                              v-text="data.item.slice(0, 1).toUpperCase()"
                            ></v-avatar>
                            {{ data.item }}
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="max-width-for-elements">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-textarea
                        prepend-icon="comment"
                        :rules="adminInfoRules"
                        v-model="user.adminInfo"
                        counter
                        class="py-2"
                        outlined
                        :label="$t('datatable.tableHeaders.adminInfo')"
                      ></v-textarea>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="mb-15 max-width-for-elements" style="margin-bottom: 150px;">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <v-select prepend-icon="mdi-account-box-multiple"
                        v-model="user.roles"
                        :items="rolesList"
                        :rules="applicationRoleRules"
                        chips
                        class="py-2"
                        clearable
                        :label="$t('datatable.tableHeaders.applicationRoles')"
                        multiple
                        outlined
                        required
                      ></v-select>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
        </v-card>
      </v-form>
       </v-card>
    </v-dialog>
 </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Constants from '@/constants';
import AppMixin from '@/plugins/AppMixins';

export default {

  props: {
    isNewRoleAddedEvent: {
      type: String,
      required: true,
    },
    isUseUpdateDialog: {
      type: Boolean,
      required: false,
    },
    selectedItem: {
      type: Object,
      required: false,
    },
    closeEditDialogProps: {
      type: Function,
      required: false,
    },
  },

  mixins: [AppMixin],

  data() {
    return {
      isValid: false,
      addNewUserDialogId: false,
      rolesList: [],
      user: {
        id: null,
        userName: '',
        email: '',
        userType: '',
        adminInfo: '',
        roles: [],
      },
      ghmUserTypesList: ['User', 'Administrator'],
      GLOBAL_EVENT_BUS_FOR_SNACK_BAR: Constants.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
    };
  },

  /**
   * mouning initial components
   */
  async mounted() {
    /**
     * Initialize data when component mounted. (page initial render)
     */
    this.fetchAllAvaialbleRolesForUser();
  },

  methods: {
    ...mapActions(['addNewUser', 'updateUser', 'findAllRolesForUser']),

    /**
     * Method to fetch all available roles for Admin.
     */
    async fetchAllAvaialbleRolesForUser() {
      console.log('------------ fetching fetchAllAvaialbleRolesForUser ********** ');
      this.rolesList = await this.findAllRolesForUser();
      console.log('------------ fetchAllAvaialbleRolesForUser ********** ', this.rolesList);
      // this.rolesList = responseData;
    },

    /**
     * Method to add new user.
     */
    async onClickOfNewUser() {
      console.log('------Add new user  ---------', this.user, ' : ', this.$i18n.locale);
      this.user.lang = this.$i18n.locale;
      this.isValidationError = false;
      const addUserResponseMsg = await this.addNewUser(this.user);
      console.log('-------------ssssxxxxxx---------------- > ', addUserResponseMsg);

      // Call mixin method to handle response.
      this.handleApiResponse(addUserResponseMsg, 'New User');
      this.$emit('RefreshUserListEvent', this.user.userName);
      console.log('--------add new user dialog ----- ', this.isValidationError);
      if (!this.isValidationError) {
        this.addNewUserDialogId = false;
      }
    },

    /**
     * Method to add new user.
     */
    async onClickOfUpdateUser() {
      console.log('------Add new user  ---------', this.user, ' : ', this.$i18n.locale);
      this.user.lang = this.$i18n.locale;
      this.isValidationError = false;
      const updateUserResponseMsg = await this.updateUser(this.user);
      console.log('-------------updateUserResponseMsg user---------------- > ', updateUserResponseMsg);

      // Call mixin method to handle response.
      this.handleApiResponse(updateUserResponseMsg, 'Update User');
      this.$emit('RefreshUserListEvent', this.user.userName);
      console.log('--------Update new user dialog ----- ', this.isValidationError);
      if (!this.isValidationError) {
        this.addNewUserDialogId = false;
      }
      this.closeDialog();
    },

    /**
     * open dialog
     */
    openDialog() {
      this.addNewUserDialogId = true;
      this.isValidationError = false;
      this.user.id = null;
      this.user.userName = '';
      this.user.email = '';
      this.user.userType = '';
      this.user.adminInfo = '';
      this.user.roles = [];
    },

    /**
     * close dialog
     */
    closeDialog() {
      this.closeEditDialogProps();
      this.addNewUserDialogId = false;
    },

  },
  computed: {

    ...mapGetters(['getApplicationError']),

    /**
     * Check if update or add call.
     */
    isUpdateDialog() {
      console.log('----- is User udpate call >>>>> ?? ', (this.user && this.user.id));
      if (this.user && this.user.id) {
        console.log(' YES : Update call ');
        return true;
      }
      return false;
    },

    userNameRules() {
      return [
        (value) => !!value || this.$t('userValidations.userNameIsRequired'),
        (value) => (value && value.length >= 3)
          || this.$t('userValidations.userNameMustHaveCharacter'),
        (value) => (value && value.length <= 100)
          || this.$t('userValidations.userNameMaxCharacter'),
      ];
    },
    emailRules() {
      return [
        (value) => !!value || this.$t('userValidations.emailIdIsRequired'),
        (value) => (value && (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)) || this.$t('userValidations.invalidEmailAddress'),
        (value) => (value && value.length >= 10)
          || this.$t('userValidations.emailMustHaveCharacter'),
        (value) => (value && value.length <= 100)
          || this.$t('userValidations.emailMaxCharacter'),
      ];
    },
    userTypeRules() {
      return [
        (value) => !!value || this.$t('userValidations.userTypeShouldNotBeEmpty'),
        (value) => value.length > 0
          || this.$t('userValidations.userTypeShouldNotBeEmpty'),
      ];
    },

    adminInfoRules() {
      return [
        (value) => (value && value.length <= 2000)
          || this.$t('userValidations.adimnInfoMaxCharacterLimit'),
      ];
    },
    applicationRoleRules() {
      return [
        (value) => (value && value.length > 0) || this.$t('userValidations.roleShouldNotBeEmpty'),
      ];
    },
  },

  watch: {

    /**
     * Watch if new role added, if new role added then refresh the roles from DB
     */
    isNewRoleAddedEvent(newVal, oldVal) { // watch it
      console.log('----- isNewRoleAddedEvent >>> Prop changed: ', newVal, ' | was: ', oldVal);
      this.fetchAllAvaialbleRolesForUser();
    },

    isUseUpdateDialog(newVal, oldVal) {
      console.log('-------isUseUpdateDialog watch is ----- ', newVal, ' : ', oldVal);
      this.addNewUserDialogId = newVal;
    },

    selectedItem(newVal, oldVal) {
      console.log('-------selectedItem watch is ----- ', newVal, ' : ', oldVal);
      if (newVal && Object.prototype.hasOwnProperty.call(newVal, 'id')) {
        this.user.id = newVal.id;
        this.user.userName = newVal.userName;
        this.user.email = newVal.email;
        this.user.userType = newVal.userTypeEnum === Constants.APPLICATION_ROLE_ADMIN ? 'Administrator' : 'User';
        this.user.adminInfo = newVal.adminInfo;
        this.user.roles = newVal.applicationRoles;
      } else {
        console.log('-------selectedItem watch is ----- ', newVal);
      }
    },
  },
};
</script>
<style scoped>

.max-width-for-elements {
  max-width: 800px !important;
}

.v-dialog:not(.v-dialog--fullscreen) {
    bottom: 0 !important;
    right: 0 !important;
    position: absolute !important;
}

</style>
