<template>
    <GhmDataTable
      :dataList="rolesList"
      :itemsPerPageProps="itemsPerPage"
      :itemsPerPageOptionsProps="itemsPerPageOptions"
      @GhmDataTAbleDeleteItemEvent="deleteRole"
      :tableType="APP_DATATABLE_TYPE_ACTION_EDIT_DELETE">
      <template v-slot:addNewRowButton>
        <AddRoleDialog @FetchUpdatedRoleListEvent="invokeFetchUpdatedRoleListEvent" />
      </template>
      <template v-slot:editDialogContentSlot="{selectedItem, closeEditDialog}">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('admin.userManagement.updateApplicationRole') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
               <v-row>
              <v-col>
                 <v-alert  dense
                     outlined v-if="isValidationError"
                  type="error">
                  {{ $t('userValidations.ADMIN_ITEM_ALREADY_EXIST', {item: "Application Role"}) }}
                  </v-alert>
              </v-col>
            </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                  v-model="selectedItem.roleName" :label="roleNameLabel" required></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              outlined
              @click="closeEditDialog()"
            >{{ $t('app.button.close') }}</v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              @click.stop="onClickOfUpdatedRole(selectedItem, closeEditDialog)"
            >{{ $t('app.button.update') }}</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </GhmDataTable>
</template>
<script>
import GhmDataTable from '@/components/app/GhmDataTable.vue';
import Constants from '@/constants';
import AddRoleDialog from '@/components/admin/AddRoleDialog.vue';
import { mapActions } from 'vuex';
import AppMixin from '@/plugins/AppMixins';
import ItemsPerPageMixin from '@/plugins/ItemsPerPageMixin';

export default {
  components: {
    GhmDataTable,
    AddRoleDialog,
  },

  mixins: [AppMixin, ItemsPerPageMixin],

  async mounted() {
    console.log('-----------mounting start ----------------------');
    /**
     * Initialize data when component mounted. (page initial render)
     */
    await this.fetchAllAvaialbleRoles();
    this.setPageTitleOfThisPage();
    console.log('-----------mounting end ----------------------');
  },
  data() {
    return {
      tab: null,
      addRoleId: '',
      pageTitle: 'admin.userManagement.roleTab',
      roleNameLabel: this.$t('admin.userManagement.roleNameLabel'),
      GLOBAL_EVENT_BUS_FOR_SNACK_BAR: Constants.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
      APP_DATATABLE_TYPE_ACTION_EDIT_DELETE:
        Constants.APP_DATATABLE_TYPE_ACTION_EDIT_DELETE,
      rolesList: [],
    };
  },

  methods: {
    ...mapActions(['setPageTitle', 'findAllRoles', 'deleteApplicationRole', 'updateApplicationRole']),

    /**
     * Method to fetch all available roles for Admin.
     */
    async fetchAllAvaialbleRoles() {
      console.log('------------ fetching all roles ********** ');
      this.rolesList = await this.findAllRoles();
      if (this.rolesList) {
        this.calculateItemsPerPage(this.rolesList.length);
      }
      console.log('------------ all roles ********** ', this.rolesList);
      // this.rolesList = responseData;
    },

    /**
     * update role.
     */
    async onClickOfUpdatedRole(selectedItem, closeEditDialog) {
      console.log('------------ on click of update role ********** ', selectedItem);
      this.isValidationError = false;
      if (selectedItem) {
        const isUpdatedResponse = await this.updateApplicationRole({
          id: selectedItem.id,
          roleName: selectedItem.roleName,
        });

        // Call mixin method to handle response.
        this.handleApiResponse(isUpdatedResponse, 'Application Role');

        // Refresh role list on deletion.
        await this.fetchAllAvaialbleRoles();

        if (!this.isValidationError) {
          closeEditDialog();
        }
      }
    },
    /**
     * add role update list event
     */
    invokeFetchUpdatedRoleListEvent(event) {
      console.log(
        '-----------invokeFetchUpdatedRoleListEvent --------- : ',
        event,
      );
      if (event) {
        this.fetchAllAvaialbleRoles();
        this.$emit('FetchUpdatedRoleListEvent', event);
      }
    },

    /**
     * Delete application role.
     */
    async deleteRole(id) {
      console.log('------Delete Role Event---------------', id);
      if (id) {
        const deleteRoleResponse = await this.deleteApplicationRole(id);

        // Call mixin method to handle response.
        this.handleApiResponse(deleteRoleResponse, 'Application Role');

        // Refresh role list on deletion.
        await this.fetchAllAvaialbleRoles();
      }
    },

    setPageTitleOfThisPage() {
      console.log('--------------role list tityle METHOD setPageTitleOfThisPage ---------------');
      this.setPageTitle(this.pageTitle);
    },

  },
};
</script>
<style scoped>
</style>
