<template>
   <GhmDataTable
      :dataList="usersList"
      :itemsPerPageProps="itemsPerPage"
      :itemsPerPageOptionsProps="itemsPerPageOptions"
      @GhmDataTAbleDeleteItemEvent="deleteUserEmitter"
      :tableType="APP_DATATABLE_TYPE_ACTION_EDIT_DELETE">
      <template v-slot:addNewRowButton>
        <AddNewUserDialog
              @RefreshUserListEvent="refreshUserListEmitter"
              :isNewRoleAddedEvent="isNewRoleAddedEvent"
           />
      </template>
      <template v-slot:editUserDialogContentSlot="{editDialog, selectedItem, closeEditDialog}">
      <AddNewUserDialog :isUseUpdateDialog="editDialog" :selectedItem="selectedItem"
                    @RefreshUserListEvent="refreshUserListEmitter"
                    :isNewRoleAddedEvent="isNewRoleAddedEvent"
                    :closeEditDialogProps="closeEditDialog" />
      </template>
   </GhmDataTable>
</template>
<script>
import GhmDataTable from '@/components/app/GhmDataTable.vue';
import { mapActions } from 'vuex';
import Constants from '@/constants';
import AddNewUserDialog from '@/components/admin/AddNewUserDialog.vue';
import AppMixin from '@/plugins/AppMixins';
import ItemsPerPageMixin from '@/plugins/ItemsPerPageMixin';

export default {

  props: {
    isNewRoleAddedEvent: {
      type: String,
      required: true,
    },
  },

  components: {
    GhmDataTable,
    AddNewUserDialog,
  },

  mixins: [AppMixin, ItemsPerPageMixin],

  /**
   * Initialize data when component mounted. (page initial render)
   */
  async mounted() {
    // Fetch all users from DB
    await this.fetchAllAvailableUsersForAdmin();
    this.setPageTitleOfThisPage();
  },

  data() {
    return {
      tab: null,
      usersList: [],
      pageTitle: 'admin.userManagement.userTab',
      GLOBAL_EVENT_BUS_FOR_SNACK_BAR: Constants.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
      APP_DATATABLE_TYPE_ACTION_EDIT_DELETE:
        Constants.APP_DATATABLE_TYPE_ACTION_EDIT_DELETE,
    };
  },

  methods: {

    ...mapActions(['setPageTitle', 'findAllUsersForAdmin', 'deleteUserByAdmin']),

    /**
     * Method to fetch all available users for Admin.
     */
    async fetchAllAvailableUsersForAdmin() {
      console.log('------------ fetching all users ********** ');
      this.usersList = await this.findAllUsersForAdmin();
      if (this.usersList) {
        this.calculateItemsPerPage(this.usersList.length);
      }
      console.log('------------ all users ********** ', this.usersList);
    },

    /**
     * Delete application role.
     */
    async deleteUserEmitter(id) {
      console.log('------Delete User Event---------------', id);
      if (id) {
        const deleteUserResponse = await this.deleteUserByAdmin(id);

        // Call mixin method to handle response.
        this.handleApiResponse(deleteUserResponse, 'User');

        // Refresh list after deletion.
        await this.fetchAllAvailableUsersForAdmin();
      }
    },

    /**
     * Refresh user list when new user gets added.
     */
    async refreshUserListEmitter(event) {
      if (event) {
        await this.fetchAllAvailableUsersForAdmin();
      }
    },

    setPageTitleOfThisPage() {
      console.log('--------------user list page tityle METHOD setPageTitleOfThisPage ---------------');
      this.setPageTitle(this.pageTitle);
    },

  },

};
</script>
<style scoped>
</style>
