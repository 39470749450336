<template>
  <v-row justify="center">
    <v-dialog v-model="addRoleDialogId" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          color="primary accent-2"
          top
          right
          absolute
          @click.stop="openDialog()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
       <v-form v-model="isValid">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('admin.userManagement.addApplicationRole') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
              <v-row>
              <v-col>
                 <v-alert  dense
                     outlined v-if="isValidationError"
                  type="error">
                  {{ $t('userValidations.ADMIN_ITEM_ALREADY_EXIST', {item: "Application Role"}) }}
                  </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field :rules="roleNameRules"
                v-model="roleName" :label="roleNameLabel" required></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" :disabled="!isValid"
          outlined @click="addRoleDialogId = false">{{$t('app.button.close')}}</v-btn>
          <v-btn color="blue darken-1" outlined :disabled="!isValid"
          @click.stop="onClickOfAddNewRole()">{{ $t('app.button.add') }}</v-btn>
        </v-card-actions>
      </v-card>
       </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Constants from '@/constants';
import AppMixin from '@/plugins/AppMixins';

export default {

  data() {
    return {
      isValid: false,
      addRoleDialogId: false,
      roleName: '',
      roleNameLabel: this.$t('admin.userManagement.roleNameLabel'),
      GLOBAL_EVENT_BUS_FOR_SNACK_BAR: Constants.GLOBAL_EVENT_BUS_FOR_SNACK_BAR,
    };
  },

  mixins: [AppMixin],

  methods: {

    ...mapActions(['addApplicationRole']),

    /**
     * Method to add new role.
     */
    async onClickOfAddNewRole() {
      console.log('------Add new role ---------', this.roleName);
      this.isValidationError = false;

      const roleAdditionResponse = await this.addApplicationRole({
        roleName: this.roleName,
      });

      console.log('*********** applciation errror in add role :>>>> ', this.getApplicationError);

      // Call mixin method to handle response.
      this.handleApiResponse(roleAdditionResponse, 'Application Role');

      this.$emit('FetchUpdatedRoleListEvent', this.roleName);
      console.log('--------add new role isValidation dialog ----- ', this.isValidationError);
      if (!this.isValidationError) {
        this.addRoleDialogId = false;
      }
    },

    openDialog() {
      this.addRoleDialogId = true;
      this.isValidationError = false;
      this.roleName = '';
    },

  },
  computed: {

    ...mapGetters(['getApplicationError']),

    roleNameRules() {
      return [
        (value) => !!value || this.$t('userValidations.roleNameIsRequired'),
        (value) => (value && value.length >= 6) || this.$t('userValidations.roleNameMustHaveCharacter'),
        (value) => (value && value.length <= 50) || this.$t('userValidations.roleNameMaxCharacter'),
      ];
    },
  },
};
</script>
<style scoped>
</style>
